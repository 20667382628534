import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TermsAndConditions: React.FC = () => {
  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Privacy Policy
        </Typography>
        <Typography variant="body2" paragraph>
          Last updated: May 12, 2024
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information
          when you use the Service and informs you about your privacy rights and how the law protects you.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Interpretation and Definitions
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Interpretation
        </Typography>
        <Typography variant="body1" paragraph>
          The words with capitalized initials have meanings defined under the following conditions. The definitions provided
          shall have the same meaning regardless of whether they appear in singular or plural.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          For the purposes of this Privacy Policy:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Account</strong> means a unique account created for you to access our Service or parts of our Service.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party,
              where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote
              for the election of directors or other managing authority.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Company</strong> (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to DentStock.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cookies</strong> are small files that are placed on your computer, mobile device, or any other device by a website,
              containing details of your browsing history on that website among its many uses.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Country</strong> refers to the United Kingdom.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Device</strong> means any device that can access the Service, such as a computer, a cellphone, or a digital tablet.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Service</strong> refers to the Website.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company,
              perform services related to the Service, or assist the Company in analyzing how the Service is used.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure
              itself (for example, the duration of a page visit).
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Website</strong> refers to DentStock, accessible from https://dentstock.co.uk/.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          Collecting and Using Your Personal Data
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Types of Data Collected
        </Typography>
        
        <Typography variant="h6" component="h4" gutterBottom>
          Personal Data
        </Typography>
        <Typography variant="body1" paragraph>
          While using our Service, we may ask you to provide us with certain personally identifiable information that can be used
          to contact or identify you. Personally identifiable information may include, but is not limited to:
        </Typography>
        <ul>
          <li><Typography variant="body1">Email address</Typography></li>
          <li><Typography variant="body1">First name and last name</Typography></li>
        </ul>

        <Typography variant="h6" component="h4" gutterBottom>
          Usage Data
        </Typography>
        <Typography variant="body1" paragraph>
          Usage Data is collected automatically when using the Service. Usage Data may include information such as your Device's Internet Protocol address
          (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages,
          unique device identifiers, and other diagnostic data.
        </Typography>
        <Typography variant="body1" paragraph>
          When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to,
          the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system,
          the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.
        </Typography>
        <Typography variant="body1" paragraph>
          We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.
        </Typography>

        <Typography variant="h6" component="h4" gutterBottom>
          Tracking Technologies and Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          We use Cookies and similar tracking technologies to track the activity on our Service and store certain information. Tracking technologies used may
          include beacons, tags, and scripts to collect and track information and to improve and analyze our Service. The technologies we use may include:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on your Device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser setting so that it will refuse cookies, our Service may use cookies.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).
            </Typography>
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser. Learn more about cookies on the Free Privacy Policy website article.
        </Typography>

        <Typography variant="body1" paragraph>
          We use both Session and Persistent Cookies for the purposes set out below:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Necessary / Essential Cookies:</strong>
            </Typography>
            <Typography variant="body1">
              Type: Session Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies are essential to provide you with services available through the Website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that you have asked for cannot be provided, and we only use these Cookies to provide you with those services.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cookies Policy / Notice Acceptance Cookies:</strong>
            </Typography>
            <Typography variant="body1">
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Functionality Cookies:</strong>
            </Typography>
            <Typography variant="body1">
              Type: Persistent Cookies
              <br />
              Administered by: Us
              <br />
              Purpose: These Cookies allow us to remember choices you make when you use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" paragraph>
          For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Use of Your Personal Data
        </Typography>
        <Typography variant="body1" paragraph>
          The Company may use Personal Data for the following purposes:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>To provide and maintain our Service:</strong> including to monitor the usage of our Service.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>To manage your Account:</strong> to manage your registration as a user of the Service. The Personal Data you provide can give you access to different functionalities of the Service that are available to you as a registered user.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>For the performance of a contract:</strong> the development, compliance, and undertaking of the purchase contract for the products, items, or services you have purchased or of any other contract with us through the Service.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>To contact you:</strong> To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products, or contracted services, including the security updates, when necessary or reasonable for their implementation.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>To provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.
              </strong>
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>To manage your requests:</strong> To attend and manage your requests to us.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>For business transfers:</strong> We may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our Service users is among the assets transferred.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>For other purposes:</strong> We may use your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our Service, products, services, marketing, and your experience.
            </Typography>
          </li>
        </ul>

        <Typography variant="body1" paragraph>
          We may share your personal information in the following situations:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>With Service Providers:</strong> We may share your personal information with Service Providers to monitor and analyze the use of our Service, to contact you.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>For business transfers:</strong> We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>With business partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>With other users:</strong> when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>With your consent:</strong> We may disclose your personal information for any other purpose with your consent.
            </Typography>
          </li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          GDPR Compliance
        </Typography>
        <Typography variant="body1" paragraph>
          We are committed to protecting your privacy and complying with the General Data Protection Regulation (GDPR). Below, we outline how we collect, use, and protect your personal data in accordance with GDPR requirements:
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Lawful Basis for Processing
        </Typography>
        <Typography variant="body1" paragraph>
          We will only collect and process your personal data when we have a lawful basis to do so, as outlined by GDPR. This may include obtaining your consent, fulfilling a contract, legal obligations, or legitimate interests.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Rights of Data Subjects
        </Typography>
        <Typography variant="body1" paragraph>
          Under GDPR, you have certain rights regarding your personal data, including the right to access, rectify, erase, or restrict processing. You also have the right to data portability and to object to processing under certain circumstances. If you wish to exercise any of these rights, please contact us using the details provided in this Privacy Policy.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          International Data Transfers
        </Typography>
        <Typography variant="body1" paragraph>
          Your personal data may be transferred to and processed in countries outside the European Economic Area (EEA) where data protection laws may differ. In such cases, we will ensure that adequate safeguards are in place to protect your data in accordance with GDPR requirements.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction, in line with GDPR requirements.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Data Retention
        </Typography>
        <Typography variant="body1" paragraph>
          We will retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. We will securely delete or anonymize your data when it is no longer needed, in accordance with GDPR principles.
        </Typography>

        <Typography variant="h6" component="h3" gutterBottom>
          Updates to Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this privacy policy from time to time to reflect changes in our practices or legal requirements, including those of GDPR. We encourage you to review this policy periodically for any updates.
        </Typography>

        <Typography variant="body1" paragraph>
          By using our website or services, you consent to the collection and use of your information as described in this Privacy Policy.
        </Typography>

      </Box>
    </Container>
  );
};

export default TermsAndConditions;
