import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAa4hSo31PL8gr-7pNyX1eRSCk5MRAxIUA",
  authDomain: "dentstock-4a733.firebaseapp.com",
  projectId: "dentstock-4a733",
  storageBucket: "dentstock-4a733.appspot.com",
  appId: "412106502098"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
