import React from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const ConsultingShowcase = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Typography variant="h3" component="h1" gutterBottom align="center" sx={{ mb: 4 }}>
            Consulting
          </Typography>
          <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3 }}>
            Let Us Help You Strategise, Consolidate, and Save Money.
          </Typography>
          <Typography variant="h6" gutterBottom align="center" sx={{ mb: 4 }}>
            Here to Help You Recalibrate for Greater Success.
          </Typography>

          <Box my={6}>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3 }}>
              Risk-Free Savings Consulting
            </Typography>
            <Typography variant="body1" paragraph align="center">
              DentStock is able to deliver massive savings to our consulting clients by administering a
              strategic, structured operating plan that has a proven track record of success. With our
              innovative procurement process, we can guarantee savings along with doctor and
              organisational buy-in.
            </Typography>
            <Typography variant="body1" paragraph align="center">
              We help you organise, consolidate, and maximise organisational earning potential. Available
              only for DSOs with multiple locations.
            </Typography>
          </Box>

          <Box my={6}>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
              Our Consulting Process
            </Typography>

          </Box>

          <Box my={6}>
            <Typography variant="h5" gutterBottom align="center" sx={{ mb: 4 }}>
              The Breakdown
            </Typography>
           
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={4} justifyContent="center">
        {[
          {
            title: "Phase 1: Distributor Request for Proposals (RFPs)",
            objective: "To identify which distributors represent the best pricing, value, and overall partnership for your DSO.",
            details: [
            ]
          },
          {
            title: "Phase 2: Non-Clinical Consolidation",
            objective: "To consolidate the non-clinically sensitive products on your practice's ordering list.",
            details: [
            ]
          },
          {
            title: "Phase 3: Clinical Consolidation",
            objective: "To identify which manufacturers are willing to provide the best pricing, service, and overall partnership to your practice.",
            details: [
            ]
          }
        ].map((phase, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom align="center" sx={{ mb: 3 }}>
                  {phase.title}
                </Typography>
                <Typography variant="body1" paragraph align="center">
                  <strong>Objective:</strong> {phase.objective}
                </Typography>
                <List>
                  {phase.details.map((detail, idx) => (
                    <ListItem key={idx}>
                      <ListItemIcon>
                        <CheckCircleRoundedIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={detail} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Box my={6}>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
              The DentStock Promise
            </Typography>
            <Typography variant="body1" paragraph align="center">
              DentStock is so confident in the services that they provide, they only bill their clients when they
              actually deliver savings to the organisation.
            </Typography>
            <Typography variant="body1" paragraph align="center">
              With DentStock's transparent pricing model, their consulting clients are strictly billed on a
              percentage of the savings that DentStock is able to provide to their clients. No onboarding
              costs, no monthly retainers, only transparent billing as a percentage of the savings delivered.
            </Typography>
            <Typography variant="body1" paragraph align="center">
              100% Risk-Free Guarantee that you don't owe DentStock anything until they can deliver results
              and savings.
            </Typography>
          </Box>

          <Box my={6}>
            <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
              DentStock Consulting Services Dental GPO
            </Typography>
            <Typography variant="h5" gutterBottom align="center" sx={{ mb: 3 }}>
              Consulting Services
            </Typography>
            <List>
              {[
                "Purchasing Creation and Consolidation",
                "Lab Consolidation",
                "Office supplies Consolidation",
                "Favourites creation",
                "Procurement software",
                "Establish Department KPIs",
                "Establish Departmental Standard Operating Procedures",
                "Ad Hoc Special Projects (Supply Chain) — Rates vary depending on requested deliverables"
              ].map((service, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleRoundedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={service} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ConsultingShowcase;